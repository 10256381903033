import {
  PButton,
  PButtonPure,
  PIcon,
  PInlineNotification,
  PLinkPure,
  PSpinner,
  PText,
  useToastManager,
} from '@porsche-design-system/components-react';
import { useRoutes } from 'hooks/useRoutes';
import { observer } from 'mobx-react-lite';
import { AddressWidget } from 'pages/activation/components/AddressWidget';
import { BusinessVerification } from 'pages/activation/components/BusinessVerification';
import { PaymentWidget } from 'pages/activation/components/PaymentWidget';
import { FC, useCallback, useContext, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { Routes } from 'router/routes';
import {
  NetworkStatus,
  subscriptionContext,
} from '../../../../components/context/subscription/SubscriptionContext';
import { UpgradeNotification } from '../../../../components/manage/notifications/UpgradeNotification';
import bannerBackground from '../../../../media/bannerImage.jpeg';
import { messages as paymentWidgetMessages } from '../../../activation/components/PaymentWidget/paymentWidget.messages';
import { OverviewTile } from '../overviewTile/OverviewTile';
import { ChargePointRegistrationWidget } from './chargePointRegistrationWidget';
import { messages } from './subscriptionManagement.messages';
import style from './subscriptionManagement.module.scss';
import { useSubscriptionManagement } from './useSubscriptionManagement';

export enum EditableField {
  PAYMENT = 'payment',
  ADDRESS = 'address',
}

interface SubscriptionManagementProps {
  isDisabled: boolean;
}

export const SubscriptionManagement: FC<SubscriptionManagementProps> = observer(
  ({ isDisabled }) => {
    const context = useContext(subscriptionContext);
    const {
      activeCreditCard,
      apiKey,
      businessVerificationData,
      defaultAddressId,
      defaultPaymentData,
      editableFieldsOpenStatus,
      handleAddressChange,
      handleAddressEdit,
      handleAddressSubmit,
      handleBusinessVerificationDataChange,
      handleCloseAllFields,
      handleOpenEditableField,
      handlePaymentChange,
      handlePaymentSubmit,
      isAddressAllowed,
      isCorrectAddressType,
      isEditingAddress,
      isLoading,
      isPayPalSelected,
      isSubmitting,
      paymentPreviewText,
      requiresBusinessVerification,
      isInitialApplePaySelected,
      handleApplePayToken,
      applePayToken,
      token,
      addressSufix,
      subscriptionAddressId,
    } = useSubscriptionManagement({
      savedCurrency: context?.getCurrencyCode(),
    });
    const { addMessage } = useToastManager();
    const intl = useIntl();
    const { marketplace, locale } = useParams();
    const navigate = useNavigate();
    const routes = useRoutes();

    const editButton = useMemo(() => {
      return (
        <PButtonPure
          icon="edit"
          className={style.clickableText}
          disabled={isDisabled}
        >
          <FormattedMessage {...messages.editButtonText} />
        </PButtonPure>
      );
    }, [isDisabled]);

    const handleSubmitClick = useCallback(
      async (actionFunction: Function) => {
        try {
          await actionFunction();
          addMessage({
            text: intl.formatMessage(messages.updateActionSuccessText),
            state: 'success',
          });
          handleCloseAllFields();
        } catch (error: unknown) {
          addMessage({
            text: intl.formatMessage(messages.updateActionFailText),
          });
        }
      },
      [addMessage, intl, handleCloseAllFields],
    );

    const submitButton = useCallback(
      (callback: () => void, fieldName: string) => {
        return (
          <PButton
            className={style.submitButton}
            onClick={() => handleSubmitClick(callback)}
            disabled={
              isDisabled ||
              isSubmitting ||
              (isInitialApplePaySelected && !applePayToken) ||
              (requiresBusinessVerification &&
                (!businessVerificationData.legalName ||
                  !businessVerificationData.vat))
            }
            loading={isSubmitting}
          >
            <FormattedMessage
              {...paymentWidgetMessages.saveChangesButtonLabel}
            />
          </PButton>
        );
      },
      [
        isSubmitting,
        handleSubmitClick,
        applePayToken,
        businessVerificationData,
        requiresBusinessVerification,
        isInitialApplePaySelected,
      ],
    );

    const cancelButton = (fieldName: string) => (
      <PButton
        className={style.cancelButton}
        onClick={() => handleCloseAllFields()}
        disabled={isDisabled || isSubmitting}
        variant="secondary"
      >
        <FormattedMessage {...messages.cancelButtonText} />
      </PButton>
    );

    if (isLoading || context.network === NetworkStatus.Loading) {
      return (
        <div className={style.centered}>
          <PSpinner />
          <PText>
            <FormattedMessage {...messages.loading} />
          </PText>
        </div>
      );
    }

    if (context.network === NetworkStatus.Error) {
      return (
        <div className={style.centered}>
          <PIcon name={'close'} />
          <PText>
            <FormattedMessage {...messages.networkError} />
          </PText>
        </div>
      );
    }

    const { subscription } = context;

    const shouldHideNoSubscriptionContent =
      !subscription || subscription?.isExpired;

    return (
      <div className={style.mainWrapper}>
        <PText weight="bold" size="large" className={style.tabTitle}>
          <FormattedMessage {...messages.contractsTitle} />
        </PText>

        <div className={isDisabled ? style.disabledContent : undefined}>
          {shouldHideNoSubscriptionContent ? (
            <div className={style.bannerContainer}>
              <img
                src={bannerBackground}
                alt="Banner background"
                className={style.bannerImage}
              />

              <div className={style.bannerContentContainer}>
                <div className={style.bannerTextTitle}>
                  <FormattedMessage {...messages.bannerTitle} />
                </div>
                <div className={style.bannerTextSubtitle}>
                  <FormattedMessage {...messages.bannerSubtitle} />
                </div>

                <div
                  className={style.bannerButtonContainer}
                  onClick={() => navigate(routes(Routes.TariffWelcome))}
                >
                  <div className={style.inactiveDiv}>
                    <PLinkPure theme="dark" icon="arrow-right">
                      <FormattedMessage {...messages.bannerButtonText} />
                    </PLinkPure>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {shouldHideNoSubscriptionContent ? null : (
            <div className={style.notification}>
              <UpgradeNotification dismissible={true} />
            </div>
          )}

          <OverviewTile
            isDisabled={isDisabled || shouldHideNoSubscriptionContent}
          />

          <div
            className={style.contentMainBox}
            style={{
              pointerEvents:
                isDisabled || shouldHideNoSubscriptionContent ? 'none' : 'auto',
            }}
            onClick={
              isDisabled || editableFieldsOpenStatus[EditableField.PAYMENT]
                ? undefined
                : () => handleOpenEditableField(EditableField.PAYMENT)
            }
          >
            {!isDisabled && editableFieldsOpenStatus[EditableField.PAYMENT] ? (
              <>
                <PaymentWidget
                  apiKey={apiKey}
                  marketplace={marketplace!}
                  locale={locale!}
                  onChange={handlePaymentChange}
                  authorizationToken={token}
                  defaultPaymentMethodId={activeCreditCard?.id}
                  isPayPalSelected={isPayPalSelected}
                  isTransparent
                  currency={context.getCurrencyCode()}
                  handleApplePayTokenSave={handleApplePayToken}
                />

                {defaultPaymentData?.paymentMethodDetails ? (
                  <>
                    <div className={style.inlineNotification}>
                      <PIcon name="information" color="notification-info" />
                      <div className={style.inlineNotificationTextWrapper}>
                        <PText
                          weight="bold"
                          className={style.inlineNotificationTitle}
                        >
                          {intl.formatMessage(
                            messages.paymentMethodNotificationHeaderText,
                          )}
                        </PText>

                        <PText>
                          {intl.formatMessage(
                            messages.paymentMethodNotificationContentText,
                            {
                              porscheIdProfileSettings: (
                                <a
                                  href={`${process.env.REACT_APP_PORSCHE_ID_MY_PROFILE_FRONTEND_URL}/${marketplace}/${locale}/paymentmethods`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {intl.formatMessage(
                                    messages.porscheIdProfileSettings,
                                  )}
                                </a>
                              ),
                            },
                          )}
                        </PText>
                      </div>
                    </div>

                    <div className={style.contentPreviewActionWrapper}>
                      {submitButton(handlePaymentSubmit, EditableField.PAYMENT)}
                      {cancelButton(EditableField.PAYMENT)}
                    </div>

                    <PText
                      color="contrast-medium"
                      className={style.deletePaymentDisclaimer}
                    >
                      {intl.formatMessage(
                        paymentWidgetMessages.deleteMethodDisclaimer,
                        {
                          paymentManagerLabel: (
                            <a
                              href={`${process.env.REACT_APP_PORSCHE_ID_MY_PROFILE_FRONTEND_URL}/${marketplace}/${locale}/paymentmethods`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {intl.formatMessage(
                                paymentWidgetMessages.paymentManagerLabel,
                              )}
                            </a>
                          ),
                        },
                      )}
                    </PText>
                  </>
                ) : null}
              </>
            ) : (
              <div className={style.contentPreviewWrapper}>
                <div className={style.contentPreviewTextWrapper}>
                  <PText
                    color={
                      isDisabled || shouldHideNoSubscriptionContent
                        ? 'contrast-medium'
                        : 'primary'
                    }
                    weight="bold"
                    className={style.sectionTitle}
                  >
                    <FormattedMessage {...messages.paymentPreviewTitle} />
                  </PText>

                  {activeCreditCard?.typeDisplayName ||
                  activeCreditCard?.shopperEmail ? (
                    <PText size="x-small">{paymentPreviewText}</PText>
                  ) : (
                    <PText
                      color={
                        shouldHideNoSubscriptionContent
                          ? 'contrast-medium'
                          : 'primary'
                      }
                      size="x-small"
                    >
                      <FormattedMessage {...messages.unknownPaymentMessagge} />
                    </PText>
                  )}
                </div>

                {shouldHideNoSubscriptionContent ? null : editButton}
              </div>
            )}
          </div>

          {/* //TO-DO: enable when rfid cards implemented */}
          {/* <div className={style.contentMainBox}>
       <div className={style.contentPreviewWrapper}>
       <div className={style.contentPreviewTextWrapper}>
       <PText weight='bold' className={style.sectionTitle}>
       <FormattedMessage {...messages.rfidTitle} />
       </PText>

       <PText size='x-small'>
       <FormattedMessage {...messages.rfidSubtitle} />: 7623746767234
       </PText>
       </div>
       </div>
       </div> */}

          <div
            className={style.contentMainBox}
            style={{
              pointerEvents:
                isDisabled || shouldHideNoSubscriptionContent ? 'none' : 'auto',
            }}
          >
            {!isDisabled && editableFieldsOpenStatus[EditableField.ADDRESS] ? (
              <>
                <AddressWidget
                  authorizationToken={token}
                  defaultAddressId={defaultAddressId}
                  onChange={handleAddressChange}
                  onAddressEdit={handleAddressEdit}
                  preventDeleteAddressId={subscriptionAddressId}
                />

                {!isAddressAllowed ? (
                  <div className={style.error}>
                    <PInlineNotification
                      dismissButton={false}
                      state="error"
                      description={intl.formatMessage(
                        messages.addressNotAllowedError,
                      )}
                    />
                  </div>
                ) : null}

                {!isCorrectAddressType ? (
                  <div className={style.error}>
                    <PInlineNotification
                      dismissButton={false}
                      state="error"
                      description={intl.formatMessage(
                        messages.addressTypeNotAllowedError,
                      )}
                    />
                  </div>
                ) : null}

                {requiresBusinessVerification ? (
                  <>
                    <div className={style.error}>
                      <PInlineNotification
                        dismissButton={false}
                        state="error"
                        description={intl.formatMessage(
                          messages.addressRequiresValidationError,
                        )}
                      />
                    </div>
                    <BusinessVerification
                      legalName={businessVerificationData.legalName}
                      vat={businessVerificationData.vat}
                      onChange={handleBusinessVerificationDataChange}
                    />
                  </>
                ) : null}

                {isEditingAddress ? null : (
                  <>
                    {submitButton(handleAddressSubmit, EditableField.ADDRESS)}
                    {cancelButton(EditableField.ADDRESS)}
                  </>
                )}
              </>
            ) : (
              <div
                className={style.contentPreviewWrapper}
                onClick={
                  isDisabled || editableFieldsOpenStatus[EditableField.ADDRESS]
                    ? undefined
                    : () => handleOpenEditableField(EditableField.ADDRESS)
                }
              >
                <div className={style.contentPreviewTextWrapper}>
                  <PText
                    weight="bold"
                    color={
                      isDisabled || shouldHideNoSubscriptionContent
                        ? 'contrast-medium'
                        : 'primary'
                    }
                    className={style.sectionTitle}
                  >
                    <FormattedMessage {...messages.addressPreviewTitle} />
                  </PText>

                  {shouldHideNoSubscriptionContent ? null : (
                    <>
                      <PText size="x-small">
                        {subscription.invoiceAddress.company ||
                          `${subscription.invoiceAddress.firstname} ${subscription.invoiceAddress.lastname}`}
                      </PText>

                      {subscription.invoiceAddress.company && addressSufix ? (
                        <PText size="x-small">{addressSufix}</PText>
                      ) : null}
                      {subscription.invoiceAddress.company ? (
                        <PText size="x-small">{`${subscription.invoiceAddress.firstname} ${subscription.invoiceAddress.lastname}`}</PText>
                      ) : null}
                      <PText size="x-small">{`${subscription.invoiceAddress.street} ${subscription.invoiceAddress.streetNo}`}</PText>
                      {subscription.invoiceAddress.zip &&
                      subscription.invoiceAddress.city ? (
                        <PText size="x-small">{`${subscription.invoiceAddress.zip} ${subscription.invoiceAddress.city}`}</PText>
                      ) : null}
                    </>
                  )}
                </div>

                {shouldHideNoSubscriptionContent ? null : editButton}
              </div>
            )}
          </div>

          <ChargePointRegistrationWidget
            isDisabled={isDisabled}
            inactive={shouldHideNoSubscriptionContent}
            registration={context.subscription?.chargePointRegistration}
          />
        </div>
      </div>
    );
  },
);
