import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { subscriptionContext } from '../../../context/subscription/SubscriptionContext';
import { messages } from './suspended.messages';
import { PInlineNotification } from '@porsche-design-system/components-react';

export const SuspendedNotificaion: React.FunctionComponent = observer(() => {
  const intl = useIntl();
  const subscription = useContext(subscriptionContext);

  if (!subscription.subscription?.isSuspended) {
    return null;
  }

  return (
    <PInlineNotification
      heading={intl.formatMessage(messages.heading)}
      description={intl.formatMessage(messages.description)}
      state={'error'}
      dismissButton={false}
    />
  );
});
