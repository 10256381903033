import React, { useCallback } from 'react';
import { Header } from './header/Header';
import { Point } from './point/Point';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { messages } from './detailsOverview.messages';

import style from './detailsOverview.module.scss';
import {
  PButtonPure,
  PLinkPure,
  PText,
} from '@porsche-design-system/components-react';

export const DetailsOverview: React.FunctionComponent = () => {
  const intl = useIntl();
  const params = useParams<{ locale: string; marketplace: string }>();

  const toClipboard = useCallback(
    (value: string) => () => {
      navigator.clipboard.writeText(value);
    },
    [],
  );

  return (
    <div className={style.root}>
      <div className={style.table}>
        <Header />
        <Point
          title={intl.formatMessage(messages.point1Title)}
          description={intl.formatMessage(messages.point1Description)}
          index={1}
        />
        <Point
          title={intl.formatMessage(messages.point2Title)}
          description={intl.formatMessage(messages.point2Description)}
          index={2}
        />
        <div className={style.body}>
          <div>
            <PText>
              <FormattedMessage {...messages.bank} />
            </PText>
            <div className={style.row}>
              <PText weight={'bold'} align={'right'}>
                Landesbank Baden-Württemberg
                <PButtonPure
                  className={style.listButton}
                  hideLabel={true}
                  icon="copy"
                  onClick={toClipboard('Landesbank Baden-Württemberg')}
                />
              </PText>
            </div>
          </div>
          <div>
            <PText>
              <FormattedMessage {...messages.iban} />
            </PText>
            <div className={style.row}>
              <PText weight={'bold'} align={'right'}>
                DE16 6005 0101 0008 6894 16
                <PButtonPure
                  className={style.listButton}
                  hideLabel={true}
                  icon="copy"
                  onClick={toClipboard('DE16600501010008689416')}
                />
              </PText>
            </div>
          </div>
          <div>
            <PText>
              <FormattedMessage {...messages.swift} />
            </PText>
            <div className={style.row}>
              <PText weight={'bold'} align={'right'}>
                SOLADEST600
                <PButtonPure
                  className={style.listButton}
                  hideLabel={true}
                  icon="copy"
                  onClick={toClipboard('SOLADEST600')}
                />
              </PText>
            </div>
          </div>
          <div>
            <PText>
              <FormattedMessage {...messages.referenceNumber} />
            </PText>
            <div className={style.row}>
              <PText weight={'bold'} align={'right'}>
                <FormattedMessage {...messages.invoiceNumber} />
                <PLinkPure
                  className={style.listButton}
                  href={`${process.env.REACT_APP_OHS_FRONTEND_URL}/${params.marketplace}/${params.locale}/account/orders?type=charging`}
                  target="_blank"
                  icon="external"
                />
              </PText>
            </div>
          </div>
          <PText
            className={style.disclaimer}
            color={'contrast-medium'}
            size="x-small"
          >
            <FormattedMessage {...messages.referenceNumberDisclaimer} />
          </PText>
        </div>
      </div>
    </div>
  );
};
