import React from 'react';
import { PHeading, PTag, PText } from '@porsche-design-system/components-react';
import style from './point.module.scss';

interface PointProps {
  index: number;
  title: string;
  description: string;
}

export const Point: React.FunctionComponent<PointProps> = ({
  index,
  title,
  description,
}) => {
  return (
    <div className={style.detailPoint}>
      <PTag color="primary">{index}</PTag>
      <div>
        <PHeading size="medium">{title}</PHeading>
        <PText color="contrast-medium">{description}</PText>
      </div>
    </div>
  );
};
