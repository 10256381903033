import { subscriptionContext } from 'components/context/subscription/SubscriptionContext';
import { ReactivationTag } from 'components/reactivationTag/ReactivationTag';
import { PlanVariant } from 'hooks/usePlans';
import { observer } from 'mobx-react-lite';
import { FC, useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';

type ReactivateTariffProps = {
  closeRedirectRoute?: string;
  isManagementPage?: boolean;
};

export const ReactivateTariff: FC<ReactivateTariffProps> = observer(
  ({ closeRedirectRoute, isManagementPage }) => {
    const intl = useIntl();
    const { subscription } = useContext(subscriptionContext);
    const now = new Date();
    const endDate =
      (subscription?.endDate && new Date(subscription?.endDate)) || now;

    const shouldShowReactivationTag =
      subscription?.isTerminated &&
      subscription?.endDate &&
      now < endDate &&
      PlanVariant.V1_PREMIUM_INCLUSIVE !==
        subscription.currentOrLastPhase?.plan?.variant;

    useEffect(() => {
      if (shouldShowReactivationTag) {
        const managementMainTextElement = document.getElementById(
          'managementPageMainText',
        );
        if (managementMainTextElement) {
          managementMainTextElement.style.marginBottom = '16px';
        }
      }
    }, [shouldShowReactivationTag]);

    if (!shouldShowReactivationTag) {
      return null;
    }

    const endDateReadable = intl.formatDate(endDate, {
      dateStyle: 'long',
    });

    return (
      <div style={{ marginBottom: isManagementPage ? '16px' : '' }}>
        <ReactivationTag
          endDate={endDateReadable}
          closeRedirectRoute={closeRedirectRoute}
        />
      </div>
    );
  },
);
