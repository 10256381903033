import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  point1Title: {
    defaultMessage: 'PayByLink',
    id: 'suspendedFlyout.detailsOverview.point1Title',
  },
  point1Description: {
    defaultMessage:
      'Use the link or QR code sent to your email to pay the outstanding invoices.',
    id: 'suspendedFlyout.detailsOverview.point1Description',
  },

  point2Title: {
    defaultMessage: 'Direct payment',
    id: 'suspendedFlyout.detailsOverview.point2Title',
  },
  point2Description: {
    defaultMessage: 'Make a direct payment, using the details provided below:',
    id: 'suspendedFlyout.detailsOverview.point2Description',
  },

  bank: {
    defaultMessage: 'Bank',
    id: 'suspendedFlyout.detailsOverview.bank',
  },
  iban: {
    defaultMessage: 'IBAN',
    id: 'suspendedFlyout.detailsOverview.iban',
  },
  swift: {
    defaultMessage: 'BIC/SWIFT-Code',
    id: 'suspendedFlyout.detailsOverview.swift',
  },
  referenceNumber: {
    defaultMessage: 'Reference number',
    id: 'suspendedFlyout.detailsOverview.referenceNumber',
  },
  invoiceNumber: {
    defaultMessage: 'Invoice No.',
    id: 'suspendedFlyout.detailsOverview.invoiceNumber',
  },
  referenceNumberDisclaimer: {
    defaultMessage:
      'Please ensure that you include the Invoice Number as the Reference Number with your payment. You can find this number on your latest unpaid invoice.',
    id: 'suspendedFlyout.detailsOverview.referenceNumberDisclaimer',
  },
});
